import "./App.css";
import { useState } from "react";
import Aboutme from "./components/Aboutme";
import Contact from "./components/Contact";
import Details from "./components/Details";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Skill from "./components/Skill";
import Work from "./components/Work";
import { Toaster } from "react-hot-toast";


function App() {

  const [mode,setMode] = useState(true);
  
  const selectMode = (msg) =>{
      setMode(msg)
  }

  return (
  
    <div id="main">
       <Toaster/>
      <div className={`w-screen ${mode ? 'text-richblack-25 bg-richblack-800' : 'text-richblack-800 bg-richblack-5'} `}>
        <Navbar selectMode={selectMode} mode={mode}/>
        <Details mode={mode}/>
      </div>
      
      <div className={`w-screen ${mode ? 'text-richblack-25 bg-richblack-800' : 'text-richblack-800 bg-richblack-5'} `}>
      <Aboutme mode={mode}/>
      </div>

      <div className={`w-screen ${mode ? 'text-richblack-25 bg-richblack-700' : 'text-richblack-800 bg-richblack-25'} `}>
        <Skill mode={mode}/>
      </div>

      <div className={`w-screen ${mode ? 'text-richblack-25 bg-richblack-800' : 'text-richblack-800 bg-richblack-5'} `}>
        <Work mode={mode}/>
      </div>

      <div className={`w-screen ${mode ? 'text-richblack-25 bg-richblack-700' : 'text-richblack-800 bg-richblack-25'} `}>
        <Contact mode={mode}/>
      </div>

      <div className={`w-screen ${mode ? 'text-richblack-25 bg-richblack-800' : 'text-richblack-800 bg-richblack-5'} `}>
        <Footer mode={mode}/>
      </div>
     
    </div>
  );
}


export default App;
