import React from "react";
import { CiMail } from "react-icons/ci";
import { IoCallOutline } from "react-icons/io5";
import { FaRegCopy } from "react-icons/fa6";
import { FaGithub } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import toast from "react-hot-toast";
import CopyToClipboard from "react-copy-to-clipboard";

const Contact = ({ mode }) => {
  const Mail = "jaydev.solanki888@gmail.com";
  const Number = `+91 7359416810`;

  return (
    <div
      id="contact"
      className="flex flex-col lg:py-10 lg:px-10 py-16  items-center gap-5 justify-center"
    >
      <div className="flex lg:py-5 flex-col lg:text-center mt-4 justify-center items-center gap-11">
        <div
          className={`flex justify-center items-center ${
            mode ? "bg-richblack-400" : "bg-richblack-50"
          }  py-1 px-5 rounded-xl`}
        >
          <p>Get in touch</p>
        </div>
        <div>
          <p className="font-semibold max-w-md w-72 text-center lg:w-11/12">
            What's next? Feel free to reach out to me if you're looking for a
            developer, have a query or simply want to connect.
          </p>
        </div>
      </div>

      <div className="flex flex-col lg:text-3xl md:text-2xl text-xl justify-center items-center gap-2">
        <div className=" flex justify-center items-center gap-3">
          <CiMail className="mt-1" />
          <p>{Mail}</p>
          <CopyToClipboard text={Mail}>
            <FaRegCopy
              className="mt-1"
              onClick={() => toast.success("Copied")}
            />
          </CopyToClipboard>
        </div>
        <div className=" flex justify-center items-center gap-3">
          <IoCallOutline className="mt-1" />
          <p>{Number}</p>
          <CopyToClipboard text={Number}>
            <FaRegCopy
              className="mt-1"
              onClick={() => toast.success("Copied")}
            />
          </CopyToClipboard>
        </div>
      </div>

      <div className="gap-4">
        <div>
          <p className="text-center">You ay also find e on these platforms!</p>
        </div>
        <div className="flex items-center justify-center gap-6 text-2xl mt-2">
          <a href="https://www.linkedin.com/in/jaydev-solanki-a2bb85226/">
            <FaLinkedin />
          </a>

          <a href="#">
            <FaXTwitter />
          </a>
          <a href="#">
            <FaGithub />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;
