import React from "react";
import { FaRegCopyright } from "react-icons/fa";
import { FcLike } from "react-icons/fc";

const Footer = () => {
  return (
    <div className="flex flex-row text-sm lg:text-base px-5 py-10 lg:py-6 text-center justify-center items-center">
      {/* <div className='flex'>
    <FaRegCopyright className='mt-1'/>
    <p className='flex'>2024 | Designed and </p>
    </div> */}
      <div className="flex">
        <FaRegCopyright className='lg:mt-[5px] mt-[3px] mr-1'/>
        
        2024 | Designed and coded by Jaydev Solanki
        
      </div>
    </div>
  );
};

export default Footer;
