import React from "react";
import { TypeAnimation } from "react-type-animation";
import { CiLocationOn } from "react-icons/ci";
import { GoDotFill } from "react-icons/go";
import { CiTwitter } from "react-icons/ci";
import { CiLinkedin } from "react-icons/ci";
import myImg from "../assest/images/myPhoto.jpg";

const Details = ({mode}) => {
  return (
    <div className={`flex ${mode ? 'bg-richblack-700':'bg-richblack-25'} flex-col lg:flex-row  lg:py-24  lg:px-32 lg:justify-between items-center lg:items-start  `}>
      <div className="flex flex-col md:flex-row mt-10 lg:mt-0 items-start  gap-12 flex-wrap">
      {/* 1st Div */}
        <div className="flex max-w-3xl flex-col lg:m-0 md:m-0 m-5 justify-center items-start gap-12">
          <div className="flex flex-col justify-center items-start gap-2">
            <p className="font-inter text-4xl sm:text-5xl md:text-6xl items-start font-semibold">
              <TypeAnimation sequence={["Hi, I'm Jaydev"]} speed={25} />
            </p>
            <p className="font-inter text-base sm:text-xl md:text-2xl lg:max-w-3xl ">
              I'm a <span className="font-bold text-2xl">Full STACK Developer</span>  with a focus on
              creating exceptional digital experiences that are fast,
              accessible, visually appealing, and responsive.
            </p>
          </div>

          <div className="flex flex-col justify-center items-start gap-2 ">
              <div className="flex items-center gap-2 text-lg font-semibold ">
                <CiLocationOn className="text-2xl mt-1"/>
                Junagadh, India
              </div>
              <div className="flex items-center gap-2 text-lg font-semibold ">
              <GoDotFill className="text-2xl mt-1 text-caribbeangreen-400"/>
              Available for new projects
              </div>
          </div>

          <div className="flex flex-col items-start gap-4">
          <div className="flex items-center gap-1 text-3xl">
              
              <a href="https://www.linkedin.com/in/jaydev-solanki-a2bb85226/">
              <CiLinkedin/>
          </a>
              
              </div>
          </div>
        </div>

       

      </div>
       {/* 2nd Div */}
       
       <div className="relative lg:min-w-[384px] sm:w-fit mb-10 mt-10 sm:m-5"> {/* TODO item end to start change in responsive */}

            <img src={myImg} className=" h-[320px] lg:ml-5 md:translate-x-5  md:-translate-y-6  md:h-[370px] 
            "/>

        </div>
    </div>
  );
};

export default Details;
