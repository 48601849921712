import React from "react";
import { CiShare1 } from "react-icons/ci";
import howToCode from "../assest/images/howToCode.jpg";

const Work = ({mode}) => {
  return (
    <div id="work" className="flex py-20 lg:px-16 flex-col justify-center items-center">
      <div className="flex px-[32px] w-full flex-col justify-center items-center gap-12">
        {/* 1st Section Start */}
        <section className="flex flex-col gap-4 items-center">
          <div className={`flex justify-center text-base items-center  ${mode ? 'bg-richblack-400':'bg-richblack-50'}  py-1 px-5 rounded-xl`}>
            <p>Work</p>
          </div>
          <div>
            <p className="text-xl">
              Some of the noteworthy projects I have built:
            </p>
          </div>
        </section>

        {/* 2nd Section Start */}
        <section className="p-4 lg:w-[95%] md:w-[95%] flex lg:flex-row flex-col">
          <div className={`flex py-8 ${mode ? 'bg-richblack-600' : 'bg-richblack-25' }  w-full lg:w-1/2 px-4 lg:px-10 justify-center items-center`}>
            <img
              className="h-auto max-w-full lg:max-h-[260px] lg:max-w-[460px]"
              src={howToCode}
            />
          </div>
          <div className={`flex p-6 lg:w-1/2 w-full flex-col items-start gap-6 ${mode ? 'bg-richblack-700' : 'bg-richblack-50' } `}>
            <div>
              <p className="text-2xl font-semibold">howToCode</p>
            </div>
            <div>
              <p className="font-semibold">
                howToCode is E-Learning latform where you can learn easiest way
                to learn code and kick-start your Coding career
              </p>
            </div>
            <div className="flex items-center gap-2 flex-wrap">
              {[
                "ReactJs",
                "ExpressJs",
                "MongoDb",
                "NodeJs",
                "Html",
                "Css",
                "Tailwindcss",
                "Payment Gateway",
              ].map((tech) => (
                <div
                  key={tech}
                  className={`flex ${mode ? 'bg-richblack-400':'bg-richblack-200'}  rounded-xl py-1 px-5`}
                >
                  <p>{tech}</p>
                </div>
              ))}
            </div>
            <div>
              <CiShare1 className="text-2xl" />
            </div>
          </div>
        </section>

        {/* 3rd Section Start */}
        <section className="p-4 lg:w-[95%] md:w-[95%]  flex lg:flex-row-reverse flex-col">
          <div className={`flex py-8 ${mode ? 'bg-richblack-600' : 'bg-richblack-25' } w-full lg:w-1/2 px-4 lg:px-10 shadow-white justify-center items-center`}>
            <img
              className="h-auto max-w-full lg:max-h-[260px] lg:max-w-[460px]"
              src={howToCode}
            />
          </div>
          <div className={`flex p-6 lg:w-1/2 w-full flex-col items-start gap-6 ${mode ? 'bg-richblack-700' : 'bg-richblack-50' } `}>
            <div>
              <p className="text-2xl font-semibold">Gujju-Tadka</p>
            </div>
            <div>
              <p className="font-semibold">
                Gujju-Tadka is a restaurant website that allows customers to
                easily book tables and place food orders online. It features a
                fantastic admin panel, enabling restaurant staff to efficiently
                manage their tasks and streamline operations.
              </p>
            </div>
            <div className="flex items-center gap-2 flex-wrap">
              {[
                "ReactJs",
                "ExpressJs",
                "MongoDb",
                "NodeJs",
                "Html",
                "Css",
                "Tailwindcss",
                "Payment Gateway",
              ].map((tech) => (
                <div
                  key={tech}
                  className={`flex ${mode ? 'bg-richblack-400':'bg-richblack-200'} rounded-xl py-1 px-5`}
                >
                  <p>{tech}</p>
                </div>
              ))}
            </div>
            <div>
              <CiShare1 className="text-2xl" />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Work;
