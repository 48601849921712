import React, { useState } from "react";
import { MdLightMode } from "react-icons/md";
import { MdNightsStay } from "react-icons/md";

import resume from "../assest/jaydevResume.pdf";

const Navbar = ({ selectMode, mode }) => {
  return (
    <div
      className={`flex py-6 ${
        mode ? "bg-richblack-800 justify-center items-center  " : "bg-richblack-5"
      } px-5 md:px-20 justify-between items-center`}
    >
      <div className="flex justify-between w-11/12  font-semibold items-center lg:w-full">
        <div className="text-xl lg:text-3xl font-bold">
          <p>Jaydev's Portfolio</p>
        </div>

        <div className="md:hidden">
          <button
            id="menuButton"
            className="text-2xl focus:outline-none"
            onClick={() =>
              document.getElementById("menuItems").classList.toggle("hidden")
            }
          >
            ☰
          </button>
        </div>

        {/* Navigation items */}
        <div
          id="menuItems"
          className="hidden md:flex flex-col md:flex-row justify-center items-center gap-6 px-8"
        >
          <ul className="flex flex-col lg:text-lg md:flex-row items-center gap-6">
            <li>
              <a href="#about">About</a>
            </li>
            <li>
              <a href="#skill">Skill</a>
            </li>
            <li>
              <a href="#work">Work</a>
            </li>
            <li>
              <a href="#contact">Contact</a>
            </li>
            <li></li>

            <li onClick={() => selectMode(!mode)}>
              {mode ? <MdNightsStay /> : <MdLightMode />}
            </li>

            <li>
              {/* onClick={()=>{downloadCV(PDF)}} */}
              <a href={resume} download="Resume">
                <div
                  className={` ${
                    mode
                      ? "text-richblack-800 bg-richblack-25"
                      : "text-richblack-5 bg-richblack-800"
                  }  rounded-lg  font-semibold px-3 py-1`}
                >
                  <p>Download CV</p>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
