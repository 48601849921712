import React from "react";
import Html from "../assest/logo/html-5-svgrepo-com.svg";
import Css from "../assest/logo/css-3-svgrepo-com.svg";
import Tailwindcss from "../assest/logo/icon-tailwindcss.svg";
import JavaScript from "../assest/logo/icon-javscript.svg";
import Reactjs from "../assest/logo/icon-react.svg";
import NodeJS from "../assest/logo/nodejs.svg";
import ExpressJS from "../assest/logo/expressjs-icon.svg";
import MongoDB from "../assest/logo/mongodb-svgrepo-com.svg";
import Postman from "../assest/logo/postman-icon-svgrepo-com.svg";
import Php from "../assest/logo/php-svgrepo-com.svg";
import jQuery from "../assest/logo/jquery-svgrepo-com.svg";
import MySQL from "../assest/logo/mysql-logo-svgrepo-com.svg";
import Laravel from "../assest/logo/laravel-svgrepo-com.svg";
import Android from "../assest/logo/android-color-svgrepo-com.svg";
import Java from "../assest/logo/java-svgrepo-com.svg";
import razorpay from "../assest/logo/razorpay.svg";
import "../css/Skill.css";

const Skill = ({mode}) => {
  return (
    <div id="skill" className="flex flex-col lg:py-[96px] lg:px-[80px] p-5 justify-center items-center self-stretch">
      <div className="flex flex-col lg:px-[32px] items-center gap-12 self-stretch">
        {/* 1st Div */}
        <div className="flex flex-col gap-4 self-stretch">
          <div className="flex justify-center items-center flex-col">
            <div className={`flex justify-center text-[16px] items-center ${mode ? 'bg-richblack-400':'bg-richblack-50'}  py-[4px] px-[20px] rounded-xl`}>
              <p>Skills</p>
            </div>
          </div>

          <div className="flex flex-col text-[17px] justify-center items-center self-stretch">
            <p>The skills, tools and technologies I am really good at:</p>
          </div>
        </div>

        {/* 2nd Div */}
        <div className="flex flex-col justify-between">
          {/* 1st row skill */}
          <div
            className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8 gap-4"
          >
            <div className="flex flex-col m-5 justify-center items-center gap-2 self-stretch">
              <img src={Html} className="w-[64px] h-[64px]" />
              <p className="font-inter text-xl ">Html</p>
            </div>

            <div className="flex shadow-white flex-col  m-5 justify-center items-center gap-2 self-stretch">
              <img src={Css} className="w-[64px] h-[64px]" />
              <p className="font-inter text-xl ">Css</p>
            </div>

            <div className="flex flex-col  m-5 justify-center items-center gap-2 self-stretch">
              <img src={Tailwindcss} className="w-[64px] h-[64px]" />
              <p className="font-inter text-xl ">Tailwindcss</p>
            </div>

            <div className="flex flex-col  m-5 justify-center items-center gap-2 self-stretch">
              <img src={JavaScript} className="w-[64px] h-[64px]" />
              <p className="font-inter text-xl ">JavaScript</p>
            </div>

            <div className="flex flex-col  m-5 justify-center items-center gap-2 self-stretch">
              <img src={Reactjs} className="w-[64px]  h-[64px]" />
              <p className="font-inter text-xl ">ReactJS</p>
            </div>

            <div className="flex flex-col   m-5 justify-center items-center gap-2 self-stretch">
              <img src={NodeJS} className="w-[64px] bg-white rounded-3xl p-1 h-[64px]" />
              <p className="font-inter text-xl ">NodeJS</p>
            </div>

            <div className="flex flex-col  m-5 justify-center items-center gap-2 self-stretch">
              <img
                src={ExpressJS}
                className="bg-white rounded-3xl p-[10px] w-[64px] h-[64px]"
              />
              <p className="font-inter text-xl ">ExpressJS</p>
            </div>

            <div className="flex flex-col  m-5 justify-center items-center gap-2 self-stretch">
              <img src={MongoDB} className="w-[64px] h-[64px]" />
              <p className="font-inter text-xl ">MongoDB</p>
            </div>
         
            <div className="flex flex-col  m-5 justify-center items-center gap-2 self-stretch">
              <img
                src={razorpay}
                className="bg-white rounded-3xl p-[4px] w-[66px] h-[66px]"
              />
              <p className="font-inter text-xl ">Razorpay</p>
            </div>

            <div className="flex flex-col  m-5 justify-center items-center gap-2 self-stretch">
              <img src={Postman} className="w-[64px] h-[64px]" />
              <p className="font-inter text-xl ">Postman</p>
            </div>

            <div className="flex flex-col  m-5 justify-center items-center gap-2 self-stretch">
              <img src={Php} className="w-[64px] h-[64px]" />
              <p className="font-inter text-xl ">Php</p>
            </div>

            <div className="flex flex-col  m-5 justify-center items-center gap-2 self-stretch">
              <img src={jQuery} className="w-[64px] h-[64px]" />
              <p className="font-inter text-xl ">jQuery</p>
            </div>

            <div className="flex flex-col  m-5 justify-center items-center gap-2 self-stretch">
              <img src={MySQL} className="w-[64px] bg-white rounded-3xl h-[64px]" />
              <p className="font-inter text-xl ">MySQL</p>
            </div>

            <div className="flex flex-col  m-5 justify-center items-center gap-2 self-stretch">
              <img src={Laravel} className="w-[64px]  h-[64px]" />
              <p className="font-inter text-xl ">Laravel</p>
            </div>

            <div className="flex flex-col  m-5 justify-center items-center gap-2 self-stretch">
              <img src={Android} className="w-[64px] h-[64px]" />
              <p className="font-inter text-xl ">Android</p>
            </div>

            <div className="flex flex-col  m-5 justify-center items-center gap-2 self-stretch">
              <img src={Java} className="w-[64px]  h-[64px]" />
              <p className="font-inter text-xl ">Java</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skill;
