import React from "react";
import myImg from "../assest/images/fullPhoto.jpg";

const Aboutme = ({mode}) => {
  return (
    <div id="about" className="flex flex-col lg:py-24 lg:px-20 pt-10 pb-10 ">
      <div className="flex flex-col px-8 gap-12 lg:items-center">
        {/* 1st DIV */}
        <div className="flex flex-col justify-center items-center">
          <div className={`flex justify-center lg:-mt-5 mt-5 items-center ${mode ? 'bg-richblack-400':'bg-richblack-50'} py-1 px-5 rounded-xl`}>
            <p>About me</p>
          </div>
        </div>

        {/* 2nd DIV */}
        <div className="flex flex-col lg:flex-row items-center lg:items-start gap-12">
          <div className="flex flex-col lg:items-start items-center min-w-[360px] lg:min-w-[444px] relative mt-5">
            <img
              src={myImg}
              className="w-[90%] max-w-[360px] lg:max-w-[444px] h-auto  relative z-10 lg:translate-x-7"
            />
            <div className="w-[90%] max-w-[360px] lg:max-w-[444px] h-full bg-richblack-500 mt-7 relative -z-10"></div>
          </div>

          <div className="flex flex-col lg:min-w-[444px] lg:mt-5 items-center ml-2 lg:items-start gap-6">
            <div>
              <p className="text-3xl font-semibold text-center lg:text-left">
                Curious about me? Here you have it:
              </p>
            </div>

            <div className="flex flex-col text-[18px] lg:max-w-[765px] lg:items-start sm:items-start gap-4 self-stretch">
              <p>
                I'm a passionate, self-proclaimed developer who specializes in
                full stack development (React.js & Node.js). I am very
                enthusiastic about bringing the technical and visual aspects of
                digital products to life. User experience, pixel perfect design,
                and writing clear, readable, highly performant code matters to
                me.
              </p>
              <p>
                I began my journey as a web developer in 2023, and since then,
                I've continued to grow and evolve as a developer, taking on new
                challenges and learning the latest technologies along the way.
                I'm building cutting-edge web applications using modern
                technologies such as React.js, Node.js, Express.js, Tailwindcss,
                Laravel and much more.
              </p>
              <p>
                I am very much a progressive thinker and enjoy working on
                products end to end, from ideation all the way to development.
              </p>
            
              <p>
                One last thing, I'm available for freelance work, so feel free
                to reach out and say hello! I promise I don't bite 😉
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Aboutme;
